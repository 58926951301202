exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-base-styles-js": () => import("./../../../src/pages/baseStyles.js" /* webpackChunkName: "component---src-pages-base-styles-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-templates-page-index-js": () => import("./../../../src/templates/page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */),
  "component---src-templates-post-index-js": () => import("./../../../src/templates/post/index.js" /* webpackChunkName: "component---src-templates-post-index-js" */),
  "component---src-templates-service-index-js": () => import("./../../../src/templates/service/index.js" /* webpackChunkName: "component---src-templates-service-index-js" */),
  "component---src-templates-white-paper-index-js": () => import("./../../../src/templates/whitePaper/index.js" /* webpackChunkName: "component---src-templates-white-paper-index-js" */)
}

